.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* div, span, li, ui {
    user-select: none;
} */

.MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader, .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.MuiDataGrid-cell {
    overflow-x: hidden !important;
}

.MuiDataGrid-iconSeparator{
    display: none !important;
}
  
.MuiDataGrid-columnHeaderDraggableContainer .MuiDataGrid-menuIcon {
    display: none !important;
}

.MuiDataGrid-footerContainer {
    border: none !important;
    display: flex !important;
    justify-content: flex-start !important;
}

.MuiTablePagination-root {
    color: white !important;
}

.MuiDataGrid-cell, .MuiDataGrid-columnHeader {
    font-size: 15px;
}

.react-datepicker__input-container > input{
    font-size: 16px;
    color: white;
}

.calculator-placeholder::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 16px;
    font-style: italic;
}

/* .navbar.scrolled {
    background-color: #000000af !important;
} */
